"use client";

import { useState } from 'react';
import { UserInfo } from '@/components/UserInfo'
import { cognitoUserPool } from '@/userPool';
import { callJsonFetch } from '@/api';

export default function Home() {
  const [ { value: callResult, error, pending }, setCallResult ] = useState<{error?: Error, value?: string, pending?: boolean}>({});
  const [ type, setType ] = useState("planting");
  const [ name, setName ] = useState("");

  async function callLambda() {
    try {
      setCallResult({ pending: true });
      const result = await type === "planting" ? 
        callJsonFetch({ 
          operation: "PutPlanting",
          plantingId: crypto.randomUUID(),
          planting: {
            name,
          }
        }) :
        callJsonFetch({ 
          operation: "PutPlant",
          plantId: crypto.randomUUID(),
          plant: {
            name,
          }
        });
      setCallResult({
        value: JSON.stringify(result, null, " ")
      });
    } catch (error) {
      setCallResult({ error: error instanceof Error ? error : new Error(`${error}`) });
    }
  }

  function logOut() {
    cognitoUserPool()?.getCurrentUser()?.signOut(() => location.assign("/login/"));
  }
  
  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24">
      <UserInfo />

      <select value={type} onChange={e => setType(e.target.value)}>
        <option>planting</option>
        <option>plant</option>
      </select>

      <label>
        {type === "planting" ? "Planting" : "Plant"} Name: 
        <input value={name} onChange={e => setName(e.target.value)} />
      </label>

      <button onClick={callLambda} disabled={pending}>Call /api</button>
      <button onClick={logOut}>Log Out</button>

      {error &&
        <pre className="text-wrap break-all">
          {error.message}
        </pre>}
      {callResult && 
        <pre className="text-wrap break-all">
          {callResult}
        </pre>}
    </main>
  );
}
