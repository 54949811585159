import { fetchClient } from "./client/lambda";

export async function callJsonFetch(payload: object) {
    const result = await fetchClient()(new Request("/api/call", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    }));
    return await result.json();
}